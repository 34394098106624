import { Theme } from '@/modules.d';

const testBreakpoint = (breakpoint: any, theme: Theme) => Object.prototype.hasOwnProperty.call(theme.breakpoints, breakpoint);

export const withMinMedia = (minWidth: string) => `@media (min-width: ${minWidth})`;

const withMaxMedia = (maxWidth: string) => `@media (max-width: ${maxWidth})`;

const withMinAndMaxMedia = (minWidth: string, maxWidth: string) => (
  `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`
);

export const up = (breakpoint: string) => ({ theme }: { theme: Theme }) => {
  if (!testBreakpoint(breakpoint, theme)) {
    return withMinMedia(breakpoint);
  }

  return withMinMedia(theme.breakpoints[breakpoint]);
};

export const down = (breakpoint: string) => ({ theme }: { theme: Theme}) => (
  withMaxMedia(theme.breakpoints[breakpoint])
);

export const between = (breakMin: string, breakMax: string) => ({ theme }: { theme: Theme}) => (
  withMinAndMaxMedia(theme.breakpoints[breakMin], breakMax)
);
